import { enableClient } from "@wix/cli-app/internal/platform-sdk/site";
(async function () {
  if (!window['React']) {
    await import('https://unpkg.com/react@16.14.0/umd/react.production.min.js');
    await import('https://unpkg.com/react-dom@16.14.0/umd/react-dom.production.min.js');
  }
  const { default: UsersCustomElementClass } = await import('/src/site/widgets/custom-elements/map-widget/element.tsx');

  let accessTokenListenerFn = UsersCustomElementClass.prototype.accessTokenListener;
  if (!accessTokenListenerFn) {
    const { injectGetAccessToken } = enableClient('cebe9b7d-07eb-49c7-a41c-f9f6f439397a');
    accessTokenListenerFn = injectGetAccessToken;
  }
  Object.defineProperty(UsersCustomElementClass.prototype, 'accessTokenListener', {
    writable: false,
    configurable: false,
    value: accessTokenListenerFn
  });

  customElements.define('map-widget', UsersCustomElementClass);
})();